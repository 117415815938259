/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Header from '../components/Header/header';
import Footer from '../components/Footer/footer';
import '../styles/Base/base.scss';

const Layout = ({ children }) => (
  <div>
    <Header />
    {children}
    <Footer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.any,
};

Layout.defaultProps = {
  children: '',
};

export default Layout;
