import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import Logo from '../../images/imgcomps/Logo';
// import Logo from '../../images/logo.svg';
import '../../styles/Components/header.scss';

const Header = () => {
  const [isLoggedIn,  setIsLoggedIn] = useState(false);

  useEffect(() => {
    (async () => {
      const user = firebase.auth().currentUser;
      if (user) {
        setIsLoggedIn(!isLoggedIn);
      } else {
        setIsLoggedIn(!isLoggedIn);
      }
    })();
  });

  const signOut = () => {
    firebase.auth().signOut().then(function() {
      // Sign-out successful.
      // navigateTo('/sign-in/');
    }, function(error) {
      // An error happened.
    });
  }

  return (
    <div className="header-container">
      <Link to="/">
        <Logo className="logo" />
      </Link>
      <div className="header-links">
        {/* <Link to="/contact">Contact</Link> */}
        {isLoggedIn ? (
          <>
            <a role="button" tabIndex={0} onClick={signOut}>Sign Out</a>
            <Link className="apply-now" to="/portal/">Portal</Link>
          </>
        ) : (
          <>
            <Link to="/sign-in/">Log In</Link>
            {/* <Link to="/sign-up/">Sign Up</Link> */}
            {/* <Link className="apply-now" to="/apply-now/">Apply Now</Link> */}
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
