import React from 'react';
import { Link } from 'gatsby';
import '../../styles/Components/footer.scss';
import fbIcon from '../../images/fbIcon.png';
import inIcon from '../../images/inIcon.png';
import instIcon from '../../images/instIcon.png';
import twIcon from '../../images/twIcon.png';

const Footer = () => (
  <div className="footer-container">
    <div className="footer-block">
      <div className="footer-links-1">
        <p>Connect With Us</p>
        <div className="social-icons">
          <a href='https://www.facebook.com/ReachSocialGrowth/' style={{backgroundImage: `url(${fbIcon})`}}/>
          <a href='https://twitter.com/ReachSclGrwth' style={{backgroundImage: `url(${twIcon})`}}/>
          <a href='https://www.linkedin.com/company/reach-social-growth/' style={{backgroundImage: `url(${inIcon})`}}/>
          <a href='https://www.instagram.com/reachsocialgrowth/' style={{backgroundImage: `url(${instIcon})`}}/>
        </div>
      </div>
      <div className="footer-links-2">
        <Link to="/about">About Us</Link>
        <Link to="/contact">Contact Us</Link>
        {/* <Link to="/social-report">Custom Social Report</Link> */}
        {/* <a href='/careers'>Careers</a> */}
      </div>
      <div className="footer-links-3">
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/terms-of-service">Terms of Service</Link>
        {/* <Link to="/beta-participation-agreement">Beta Participation Agreement</Link> */}
      </div>
    </div>
  </div>
);

export default Footer;
